
import { defineComponent } from "vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { config } from "@/core/helpers/config";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
// import store from "@/store";

export default defineComponent({
  name: "topbar",
  components: {
    KTUserMenu,
  },

  setup() {
    const router = useRouter();
    let mailid = store.getters.emailid;
    let accountid = store.getters.AccountId;
    let accountname = store.getters.AccountName;
    /**
     * Submit form
     * @param event
     */

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    };
    return {
      signOut,
      config,
      mailid,
      accountid,
      accountname,
    };
  },
  methods: {
    toggle(event) {
      // added event as second arg
      event.preventDefault();
      // save new config to localStorage
      localStorage.setItem("config", JSON.stringify(config.value));
    },
  },
});
